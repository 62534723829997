

function isNumberKey(evt) {
  var charCode = (evt.which) ? evt.which : event.keyCode
  if (charCode > 31 && (charCode < 48 || charCode > 57))
  return false;
}
function message() {

  var name = document.getElementById("name");
  document.getElementById("rtrmessage").style.display = "none";

}
function Clear() {
  document.getElementById("req_name").value = "";
  document.getElementById("req_company").value = "";
  document.getElementById("req_email").value = "";
  document.getElementById("req_cargo").value = "";
  document.getElementById("req_from").value = "";
  document.getElementById("req_destination").value = "";
  document.getElementById("req_goods").value = "";
  document.getElementById("req_vehicles").value = "";
  document.getElementById("req_length").value = "";
  document.getElementById("req_width").value = "";
  document.getElementById("req_height").value = "";
  document.getElementById("req_measure").value = "";
  document.getElementById("req_quantity").value = "";
  document.getElementById("req_weight").value = "";
  document.getElementById("req_load").value = "";
  document.getElementById("req_mode").value = "";
  document.getElementById("req_delivery").value = "";
  document.getElementById("req_intrested").value = "";
  document.getElementById("labour").value = "";
  document.getElementById("req_message").value = "";
}
function trim(stringToTrim) {
  return stringToTrim.replace(/^\s+|\s+$/g, "");
}
function ltrim(stringToTrim) {
  return stringToTrim.replace(/^\s+/, "");
}
function rtrim(stringToTrim) {
  return stringToTrim.replace(/\s+$/, "");
}
function validate(Email) {
  var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (reg.test(Email) == false) {
    return false;
  }
  else {
    return true;
  }
}
function MobileValidate(MobileNo) {
  var reg = /^([7-9]{1})([0-9]{9})$/;
  if (reg.test(MobileNo) == false)
  { return false; }
  else {
    return true;
  }
}
function req_mail() {
  var req_name = document.getElementById("req_name").value;
  var req_company = document.getElementById("req_company").value ;
  var req_email = document.getElementById("req_email").value;
  var req_cargo = document.getElementById("req_cargo").value;
  var req_from = document.getElementById("req_from").value;
  var req_destination = document.getElementById("req_destination").value;
  var req_goods = document.getElementById("req_goods").value;
  var req_vehicles = document.getElementById("req_vehicles").value;
  var req_length = document.getElementById("req_length").value;
  var req_width = document.getElementById("req_width").value;
  var req_height = document.getElementById("req_height").value;
  var req_measure = document.getElementById("req_measure").value;
  var req_quantity = document.getElementById("req_quantity").value;
  var req_weight = document.getElementById("req_weight").value;
  var req_load = document.getElementById("req_load").value;
  var req_mode = document.getElementById("req_mode").value;
  var req_delivery = document.getElementById("req_delivery").value;
  var req_intrested = document.getElementById("req_intrested").value;
  // var labour = document.getElementById("labour").value;
  var req_message = document.getElementById("req_message").value;

  if (trim(req_name) == "") {
      sweetAlert("Oops...", "Please enter Name.!", "error");
      return false;
  }
  if (trim(req_company) == "") {
      sweetAlert("Oops...", "Please enter company Name.!", "error");
      return false;
  }
  // if (trim(varmobileNo) == "") {
  //     sweetAlert("Oops...", "Please enter Mobile No.!", "error");
  //     return false;
  // }
  // if (!MobileValidate(varmobileNo)) {
  //     sweetAlert("Oops...", "Please enter valid Mobile No.!", "error");
  //     return false;
  // }
  if (trim(req_email) == "") {
      sweetAlert("Oops...", "Please enter Email ID!", "error");
      return false;
  }

  if (!validate(req_email)) {

      sweetAlert("Oops...", "Please enter valid Email ID!", "error");
      return false;
  }
  if (trim(req_message) == "") {
      sweetAlert("Oops...", "Please enter Msg Detail!", "error");
      return false;
  }


  var xmlhttp;

  if (window.XMLHttpRequest) {// code for IE7+, Firefox, Chrome, Opera, Safari

    xmlhttp = new XMLHttpRequest();

  }

  else {// code for IE6, IE5

    xmlhttp = new ActiveXObject("Microsoft.XMLHTTP");

  }
  swal("Email Sent!", "Thank You for contacting us, we will get back to you soon...!!!", "success")

  xmlhttp.onreadystatechange = function () {

    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {

      var varMaxQuestion = xmlhttp.responseText;

      Clear();

    }

  }

  xmlhttp.open("POST",
  "request-form.php?name=" + req_name
  + "&company=" + req_company
  + "&email=" + req_email
  + "&cargo=" + req_cargo
  + "&form=" + req_from
  + "&destination=" + req_destination
  + "&goods=" + req_goods
  + "&vehicles=" + req_vehicles
  + "&length=" + req_length
  + "&width=" + req_width
  + "&height=" + req_height
  + "&measure=" + req_measure
  + "&quantity=" + req_quantity
  + "&weight=" + req_weight
  + "&load=" + req_load
  + "&mode=" + req_mode
  + "&delivery=" + req_delivery
  + "&intrested=" + req_intrested
  + "&message=" + req_message,true);
  xmlhttp.send();

}
